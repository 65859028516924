import { Typography, Grid } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SuiSelect from "components/SuiSelect";
import Loader from "components/Loader/Loader";

const useStyles = makeStyles(() => ({
  cardContent: { margin: "0", padding: "0" },
  card: {
    marginTop: "5%",
    borderRadius: "10px",
    marginBottom: "5%",
    paddingBottom: "2%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  question: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#5c5c5c",
    marginBottom: "15px",
    textTransform: "uppercase",
  },
  skillWrapper: {
    margin: "5px",
    display: "flex",
  },
  answer: {
    fontSize: "15px",
    color: "#000000",
    marginBottom: "3px",
    padding: "15px",
  },
  images: {
    marginTop: "10px",
    marginBottom: "3px",
  },
  links: {
    marginBottom: "50px",
  },
  links1: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#3f3f3f",
  },
  links2: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#000000",
    marginBottom: "25px",
  },
  styleValuesLinks: {
    textDecoration: "underline",
  },
  styleListItemText: {
    margin: "5px",
  },
  scrollContainer: {
    maxHeight: "200px",
    overflowY: "auto",
  },
}));

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#FF7701",
        },
      },
    },
  },
});

function AiPa({ customerUser, leadIdentificator, loadingCompany }) {
  const [assistants, setAssistants] = useState([]);
  const [load, setLoad] = useState(true);
  const [lang] = useState("de");
  const [text, setText] = useState([]);
  const [openAiAssistant, setOpenAiAssistant] = useState(null);
  const classes = useStyles();

  const fetchAssistants = async () => {
    try {
      setLoad(true);
      const res = await axios.get(`/api/customers/${customerUser.customerUser.customer.id}/a3-ai`);
      setAssistants(res.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.error("Error fetching result:", error);
    }
  };

  const fetchResult = async () => {
    try {
      setLoad(true);
      const res = await axios.post(
        `/api/customers/${customerUser.customerUser.customer.id}/a3-ai/${openAiAssistant}/generate-answer`,
        { question: leadIdentificator },
        { headers: { "Accept-Language": lang } }
      );
      setText(res.data?.data || []);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.error("Error fetching result:", error);
    }
  };

  const handleValueChange = (event) => {
    setOpenAiAssistant(event.value);
  };

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    if (openAiAssistant) fetchResult();
  }, [openAiAssistant]);

  return loadingCompany ? (
    <Loader />
  ) : (
    <Grid md={12} container spacing={1} flexDirection="row" p={2}>
      <Grid container md={4.5} />
      <Grid container md={4} mt={2}>
        <Grid item xs={12} md={12}>
          <Typography component="div" flexDirection="column">
            <Grid container flexDirection="row" spacing={3} mb={5}>
              <Grid item md={8}>
                <ThemeProvider theme={theme}>
                  {assistants && assistants.length > 0 && (
                    <SuiSelect
                      defaultValue={openAiAssistant}
                      onChange={handleValueChange}
                      displayEmpty
                      placeholder="Select AI Asistance"
                      multiple={false}
                      variant="standard"
                      checkboxShow={false}
                      options={assistants.map((i) => ({
                        label: i.label,
                        value: i.value,
                      }))}
                    />
                  )}
                </ThemeProvider>
                {load ? (
                  <Loader />
                ) : (
                  text &&
                  openAiAssistant !== null && (
                    <Typography className={classes.answer}>{text}</Typography>
                  )
                )}
              </Grid>
              <Grid item md={4} />
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

AiPa.propTypes = {
  customerUser: PropTypes.any.isRequired,
  leadIdentificator: PropTypes.string.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default AiPa;
