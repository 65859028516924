/* eslint-disable react/no-array-index-key */
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";

const useStyles = makeStyles(() => ({
  question: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#5c5c5c",
    marginBottom: "15px",
    textTransform: "uppercase",
  },
  skillWrapper: {
    margin: "5px",
    display: "flex",
  },
  answer: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#000000",
    marginBottom: "3px",
  },
  images: {
    marginTop: "10px",
    marginBottom: "3px",
  },
  links: {
    marginBottom: "50px",
  },
  links1: {
    fontWeight: "600",
    fontSize: "13px",
    color: "#3f3f3f",
  },
  links2: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#000000",
    marginBottom: "25px",
  },
  styleValuesLinks: {
    textDecoration: "underline",
  },
  styleListItemText: {
    margin: "5px",
  },
  scrollContainer: {
    maxHeight: "200px", // Adjust the height as needed
    overflowY: "auto",
  },
}));

function AiP({ customerUser, leadIdentificator, loadingCompany }) {
  const [firstRun, setFirstRun] = useState(true);
  const [load, setLoad] = useState(true);
  const [lang, setLang] = useState("de");
  const [text, setText] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [pQuestions, setPQuestions] = useState([]);
  const classes = useStyles();
  const fetchResult = async (message, start = false) => {
    try {
      setLoad(true);
      const res = await axios.post(
        `/api/customers/${customerUser.customerUser.customer.id}/a2-ai`,
        { question: message, tread_id: threadId },
        {
          headers: {
            ...axios.defaults.headers,
            "Accept-Language": lang,
          },
        }
      );

      const { answer, links, questions } = JSON.parse(res.data.data);
      setThreadId(res.data.thread_id);
      const mainText = {
        question: start ? null : message,
        answer,
        links,
      };

      if (answer) {
        setText([...text, mainText]);
        setPQuestions(questions);
      }
      setLoad(false);
      setFirstRun(false);
    } catch (error) {
      setLoad(false);
      setFirstRun(false);
      console.error("Error fetching result:", error);
    }
  };

  useEffect(() => {
    setLang("de");
    fetchResult(leadIdentificator, true);
  }, []);

  return loadingCompany || firstRun ? (
    <Loader />
  ) : (
    <Grid md={12} container spacing={1} flexDirection="row" p={2}>
      {leadIdentificator && text && (
        <>
          <Grid container md={4} />
          <Grid container md={4}>
            <Grid item xs={12} md={12}>
              {text.map((item, index) => (
                <Typography key={index} component="div" flexDirection="column">
                  {item.question && (
                    <>
                      <Typography className={classes.question} sx={{ color: "orange" }}>
                        {item.question.replace(`${leadIdentificator}.`, "")}
                      </Typography>
                    </>
                  )}
                  <Grid container flexDirection="row" spacing={3} mb={5}>
                    <Grid item md={8}>
                      <Typography className={classes.answer}>{item.answer}</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography className={classes.links}>
                        <Typography className={classes.links1}>Links:</Typography>
                        {item.links.map((link, linkIndex) => (
                          <p>
                            <Typography
                              sx={{ color: "gray" }}
                              className={classes.links2}
                              key={linkIndex}
                              component="a"
                              href={link.url}
                              target="_new"
                            >
                              {link.name}
                            </Typography>
                          </p>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              ))}
              {load ? (
                <Loader />
              ) : (
                pQuestions.map((item, index) => (
                  <>
                    <br />
                    <div key={index}>
                      <Button
                        sx={{ color: "orange", width: "100%" }}
                        key={index}
                        onClick={() => fetchResult(`${leadIdentificator}. ${item}`)}
                      >
                        + {item}
                      </Button>
                    </div>
                  </>
                ))
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

AiP.propTypes = {
  customerUser: PropTypes.any.isRequired,
  leadIdentificator: PropTypes.string.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
};

export default AiP;
